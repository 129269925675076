<template>
    <div class="row breadcrumbs-top">
        <div class="col-12">
            <h2 class="content-header-title float-left mb-0" 
                v-text="subject"></h2>
            <div class="breadcrumb-wrapper col-12">
                <ol class="breadcrumb">
                    <template v-for="(crumb, index) in links">
                        <li class="breadcrumb-item" 
                            v-bind:key="index"
                            v-bind:class="{active: index==links.length-1}">
                            <template v-if="crumb.link">
                                <a :href="crumb.link" v-text="crumb.name"></a>
                            </template>
                            <template v-else>
                                {{crumb.name}}
                            </template>
                        </li>
                    </template>
                </ol>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props : [
        "subject", "links"
    ],
}
</script>
<style scoped>

</style>